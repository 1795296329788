/**
 * This file is part of Bimbo Vending QR.
 *
 * This source file is subject to a proprietary license that is bundled
 * with this source code in the file LICENSE.
 *
 * @author The Nubity Development Team <dev@nubity.com>
 */

import classNames from 'classnames';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {CategoryFieldInterface} from './model/CategoryFieldInterface';

const CategoryField: React.FC<CategoryFieldInterface> = (props) => {
  const {t} = useTranslation();
  const {activeCategoryId, id, label, type, onCategoryChange} = props;

  return (
    <div className="row" onClick={() => onCategoryChange(id)} aria-hidden="true">
      <div className="col-12">
        <div
          data-category-type={type}
          className={classNames('image-failure', {
            active: activeCategoryId === id,
          })}
        />
      </div>
      <div className="col-12">
        <p className="form-label text-center w-100">{t(label)}</p>
      </div>
    </div>
  );
};

export default CategoryField;
