/**
 * This file is part of Bimbo Vending QR.
 *
 * This source file is subject to a proprietary license that is bundled
 * with this source code in the file LICENSE.
 *
 * @author The Nubity Development Team <dev@nubity.com>
 */

import {BaseQueryFn} from '@reduxjs/toolkit/query/react';
import type {AxiosRequestConfig, AxiosError, Method} from 'axios';
import api from '../../util/api';

interface RequestBase {
  url: string;
  method: Method;
  config?: Omit<AxiosRequestConfig, 'method' | 'url' | 'baseUrl'>;
}

/**
 * Executes an asynchronous request and transforms the response to be compatible with the store.
 */
const requestBase = (baseURL = ''): BaseQueryFn<RequestBase> => {
  return async ({url, method, config = {}}) => {
    const {headers, ...configurations} = config;

    try {
      const request = await api.getRequest({...headers});

      const result = await request({
        ...configurations,
        method,
        url: `${baseURL}${url}`,
      });

      return {data: result.data};
    } catch (axiosError) {
      const err = axiosError as AxiosError;

      return {
        error: {status: err.response?.status, data: err.response?.data},
      };
    }
  };
};

export default requestBase;
