/**
 * This file is part of Bimbo Vending QR.
 *
 * This source file is subject to a proprietary license that is bundled
 * with this source code in the file LICENSE.
 *
 * @author The Nubity Development Team <dev@nubity.com>
 */

const subCategories = [
  {id: 1, type: 'lampOff', label: 'subcategory.lamp_off', category: 1},
  {id: 2, type: 'machineNotWork', label: 'subcategory.machine_does_not_work', category: 1},
  {id: 3, type: 'machineNotCool', label: 'subcategory.does_not_cool', category: 1},
  {id: 4, type: 'machineNotDeliveredProduct', label: 'subcategory.display_does_not_work', category: 1},
  {id: 8, type: 'sprintNotWork', label: 'subcategory.spring_did_not_rotate', category: 1},
  {id: 9, type: 'productStuck', label: 'subcategory.product_stuck', category: 2},
  {id: 10, type: 'productNotDelivered', label: 'subcategory.not_delivery_the_product', category: 2},
  {id: 11, type: 'productExpired', label: 'subcategory.delivered_expired_product', category: 2},
  {id: 12, type: 'machineNotReceiveCoin', label: 'subcategory.not_receive_coins', category: 3},
  {id: 13, type: 'machineNotReceiveBill', label: 'subcategory.not_receive_billets', category: 3},
  {id: 14, type: 'machineNotReturnChange', label: 'subcategory.did_not_give_change', category: 3},
  {
    id: 15,
    type: 'productNotDeliveredWithBill',
    label: 'subcategory.ate_billet_and_did_not_delivered_product',
    category: 3,
  },
  {
    id: 16,
    type: 'productNotDeliveredWithCoin',
    label: 'subcategory.ate_coin_and_did_not_delivered_product',
    category: 3,
  },
  {
    id: 17,
    type: 'productNotDeliveredWithCard',
    label: 'subcategory.paid_with_card_and_did_not_delivered_product',
    category: 3,
  },
  {id: 18, type: 'paymentNotWork', label: 'subcategory.card_payment_does_not_work', category: 3},
];

export default subCategories;
