/**
 * This file is part of Bimbo Vending QR.
 *
 * This source file is subject to a proprietary license that is bundled
 * with this source code in the file LICENSE.
 *
 * @author The Nubity Development Team <dev@nubity.com>
 */

import apiService from './apiService';
import {IssueIssueInputJsonIdInterface} from './model/IssueIssueInputJsonIdInterface';
import {IssueJsonIdIssueReadInterface} from './model/IssueJsonIdIssueReadInterface';

const issueService = apiService.injectEndpoints({
  /**
   * Handles requests from the issue service.
   */
  endpoints: (builder) => ({
    getIssue: builder.query<IssueJsonIdIssueReadInterface, number>({
      query: (id: number) => ({
        url: `/issues/${id}`,
        method: 'GET',
      }),
    }),
    postIssue: builder.mutation<IssueJsonIdIssueReadInterface, IssueIssueInputJsonIdInterface>({
      query: (body) => ({
        url: '/issues',
        method: 'POST',
        config: {data: body, headers: {'content-type': 'application/ld+json'}},
      }),
    }),
    getHelpdeskId: builder.query<IssueJsonIdIssueReadInterface, number>({
      query: (id: number) => ({
        url: `/issues/${id}/helpdesk/update-id`,
        method: 'GET',
      }),
    }),
  }),
});

export const {useGetIssueQuery, usePostIssueMutation, useGetHelpdeskIdQuery} = issueService;

export default issueService;
