/**
 * This file is part of Bimbo Vending QR.
 *
 * This source file is subject to a proprietary license that is bundled
 * with this source code in the file LICENSE.
 *
 * @author The Nubity Development Team <dev@nubity.com>
 */

/**
 * Provides help functions for handling dates.
 */
class DateUtil {
  static secondToMillisecond(second: number): number {
    const millisecond = 1000;

    return second * millisecond;
  }
}

export default DateUtil;
