/**
 * This file is part of Bimbo Vending QR.
 *
 * This source file is subject to a proprietary license that is bundled
 * with this source code in the file LICENSE.
 *
 * @author The Nubity Development Team <dev@nubity.com>
 */

import categories from '../../../form/categories';
import IssueDTO from '../../../form/dto/Issue';
import subCategories from '../../../form/subCategories';
import i18n from '../../../locale/i18n';
import {IssueInterface} from '../../../model/IssueInterface';

class IssueTransformer {
  /**
   * Transforms a DTO into an `IssueInterface`.
   *
   * @throws TypeError if `IssueDTO.category` property isn't valid.
   * @throws TypeError if `IssueDTO.subCategory` property isn't valid.
   * @throws TypeError if `IssueDTO.wallet` property isn't valid.
   */
  static transform(issue: IssueDTO): IssueInterface {
    const {category: categoryId, subCategory: subCategoryId, ...fields} = issue;

    const category = categories.find(({id}): boolean => id.toString() === categoryId);
    const subCategory = subCategories.find(({id}): boolean => id.toString() === subCategoryId);

    if (undefined === category) {
      throw new TypeError(
        `\`categoryId\` must be one of ${categories
          .map((option) => `"${option.id}"`)
          .join(', ')}; "${categoryId}" given.`,
      );
    }

    if (undefined === subCategory) {
      throw new TypeError(
        `\`subCategoryId\` must be one of ${subCategories
          .map((option) => `"${option.id}"`)
          .join(', ')}; "${subCategoryId}" given.`,
      );
    }

    let subCategoryValue = i18n.t(subCategory.label);

    // For business logic and to maintain compatibility with "Salesforce" if the user selects the "Producto" category
    // and the "Producto atorado" subcategory, the value sent as subcategory must be replace by "No giró el resorte".
    if ('product' === category.type && 'productStuck' === subCategory.type) {
      subCategoryValue = i18n.t('subcategory.spring_did_not_rotate');
    }

    return {
      category: i18n.t(category.value),
      subCategory: subCategoryValue,
      comment: fields.comment,
      name: fields.firstname,
      lastName: fields.lastname,
      email: fields.email,
      phone: fields.phone,
      spring: fields.spring,
      wallet: Boolean(fields.wallet),
    };
  }
}

export default IssueTransformer;
