/**
 * This file is part of Bimbo Vending QR.
 *
 * This source file is subject to a proprietary license that is bundled
 * with this source code in the file LICENSE.
 *
 * @author The Nubity Development Team <dev@nubity.com>
 */

import axios, {AxiosInstance} from 'axios';

const api = {
  /**
   * Returns a axios instance.
   */
  async getRequest(headers = {}): Promise<AxiosInstance> {
    const innerHeaders = {
      Accept: 'application/json',
      ...headers,
    };

    return axios.create({
      baseURL: `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_API_SUBDOMAIN}.${process.env.REACT_APP_DOMAIN}/${process.env.REACT_APP_API_VERSION}`,
      headers: innerHeaders,
    });
  },
};

export default api;
