/**
 * This file is part of Bimbo Vending QR.
 *
 * This source file is subject to a proprietary license that is bundled
 * with this source code in the file LICENSE.
 *
 * @author The Nubity Development Team <dev@nubity.com>
 */

import React from 'react';
import {useTranslation} from 'react-i18next';

export interface HelpdeskIdStatusMessageInterface {
  isTimeoutFinished: boolean;
  helpdeskId: string | undefined;
}

/**
 * Renders the helpdesk id status message.
 */
const HelpdeskIdStatusMessage: React.FC<HelpdeskIdStatusMessageInterface> = (props) => {
  const {helpdeskId, isTimeoutFinished} = props;
  const {t} = useTranslation();

  if (isTimeoutFinished && undefined === helpdeskId) {
    return <p className="fs-5 text-center">{t('component.success_message.timeout')}</p>;
  }

  if (undefined !== helpdeskId) {
    return <p className="fs-5 text-center">{t('component.success_message.message')}</p>;
  }

  return <p className="fs-5 text-center">{t('component.success_message.wait_helpdesk_id')}</p>;
};

export default HelpdeskIdStatusMessage;
