/**
 * This file is part of Bimbo Vending QR.
 *
 * This source file is subject to a proprietary license that is bundled
 * with this source code in the file LICENSE.
 *
 * @author The Nubity Development Team <dev@nubity.com>
 */

import React from 'react';
import {useTranslation} from 'react-i18next';
import {ErrorMessageInterface} from './model/ErrorMessageInterface';

/**
 * Returns a error message.
 */
const ErrorMessage: React.FC<ErrorMessageInterface> = (props) => {
  const {title, messages} = props;
  const {t} = useTranslation();

  return (
    <div>
      <div className="row justify-content-center mb-5 mt-5">
        <img src={`${process.env.PUBLIC_URL}/error.png`} alt="img-error" className="img-fluid img-error" />
      </div>
      <div className="row justify-content-center mb-3">
        <div className="col-12">
          <p className="fs-1 fw-bold text-center text-error">{t(title)}</p>
        </div>
      </div>
      <div className="row justify-content-center mb-5">
        <div className="col-12">
          {messages.map((message) => {
            return (
              <p key={message} className="fs-5 text-center">
                {t(message)}
              </p>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ErrorMessage;
