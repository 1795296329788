/**
 * This file is part of Bimbo Vending QR.
 *
 * This source file is subject to a proprietary license that is bundled
 * with this source code in the file LICENSE.
 *
 * @author The Nubity Development Team <dev@nubity.com>
 */

import React from 'react';

/**
 * Renders the layout page.
 */
const PageLayout: React.FC<React.PropsWithChildren> = (props) => {
  const {children} = props;

  return (
    <div>
      <div className="container-fluid header blue-header">
        <img src={`${process.env.PUBLIC_URL}/auto-vend.png`} alt="logo" className="header__logo" />
      </div>

      <div className="container-fluid rounded-main-border">{children}</div>
    </div>
  );
};

export default PageLayout;
