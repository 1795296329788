/**
 * This file is part of Bimbo Vending QR.
 *
 * This source file is subject to a proprietary license that is bundled
 * with this source code in the file LICENSE.
 *
 * @author The Nubity Development Team <dev@nubity.com>
 */

import {HelpdeskInterface} from '../../../model/HelpdeskInterface';
import {HelpdeskJsonIdIssueReadInterface} from '../model/HelpdeskJsonIdIssueReadInterface';

/**
 * Transform helpdesk.
 */
class HelpdeskTransformer {
  /**
   * Transforms a `HelpdeskJsonIdIssueReadInterface` into a `HelpdeskInterface`.
   */
  public static reverse(helpdesk: HelpdeskJsonIdIssueReadInterface): HelpdeskInterface {
    return {
      '@context': helpdesk['@context'],
      '@id': helpdesk['@id'],
      '@type': helpdesk['@type'],
      id: null === helpdesk.id ? undefined : helpdesk.id,
    };
  }
}

export default HelpdeskTransformer;
