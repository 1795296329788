/**
 * This file is part of Bimbo Vending QR.
 *
 * This source file is subject to a proprietary license that is bundled
 * with this source code in the file LICENSE.
 *
 * @author The Nubity Development Team <dev@nubity.com>
 */

import React from 'react';
import {useGetHelpdeskIdQuery} from '../store/service/issueService';
import HelpdeskTransformer from '../store/service/transform/HelpdeskTransformer';
import DateUtil from '../util/DateUtil';

/**
 * Retrieves the helpdesk identifier.
 * A long polling is executed until the identifier is retrieved or the maximum waiting time is exhausted.
 */
const useGetHelpdeskId = (
  identifier: number,
  timeoutTime: number,
  pollingTime: number,
): {helpdeskId: string | undefined; isTimeoutFinished: boolean} => {
  const [canStopPolling, updateCanStopPolling] = React.useState(false);
  const [isActiveTimer, setActiveTime] = React.useState(false);

  const pollingTimeRef = React.useRef(DateUtil.secondToMillisecond(pollingTime));
  const timeoutTimeRef = React.useRef(DateUtil.secondToMillisecond(timeoutTime));

  const {data} = useGetHelpdeskIdQuery(identifier, {
    pollingInterval: pollingTimeRef.current,
    skip: canStopPolling,
  });

  React.useEffect(() => {
    let timer: NodeJS.Timeout;

    if (isActiveTimer) {
      timer = setTimeout(() => {
        updateCanStopPolling(true);
      }, timeoutTimeRef.current);
    }

    return () => {
      if (undefined !== timer) {
        clearTimeout(timer);
      }
    };
  }, [isActiveTimer]);

  React.useEffect((): void => {
    if (undefined !== data) {
      setActiveTime(true);

      if (null !== data.helpdesk.id) {
        updateCanStopPolling(true);
      }
    }
  }, [data]);

  if (undefined === data) {
    return {helpdeskId: undefined, isTimeoutFinished: canStopPolling};
  }

  return {
    helpdeskId: HelpdeskTransformer.reverse(data.helpdesk).id,
    isTimeoutFinished: canStopPolling,
  };
};

export default useGetHelpdeskId;
