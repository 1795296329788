/**
 * This file is part of Bimbo Vending QR.
 *
 * This source file is subject to a proprietary license that is bundled
 * with this source code in the file LICENSE.
 *
 * @author The Nubity Development Team <dev@nubity.com>
 */

import React from 'react';

/**
 * Renders a placeholder of an element.
 */
const Loader: React.FC = () => {
  return (
    <div>
      <div className="row justify-content-center mb-5 mt-5">
        <div className="spinner" />
      </div>
      <div className="row justify-content-center mb-5">
        <div className="col-6">
          <span className="skeleton-box" />
          <span className="skeleton-box" />
        </div>
      </div>
      <div className="row justify-content-center mb-5">
        <div className="col-10">
          <span className="skeleton-box" />
          <span className="skeleton-box" />
        </div>
      </div>
    </div>
  );
};

export default Loader;
