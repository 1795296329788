/**
 * This file is part of Bimbo Vending QR.
 *
 * This source file is subject to a proprietary license that is bundled
 * with this source code in the file LICENSE.
 *
 * @author The Nubity Development Team <dev@nubity.com>
 */

import Parser from 'html-react-parser';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {PrivacyInterface} from './model/PrivacyInterface';

const Privacy: React.FC<PrivacyInterface> = (props) => {
  const {children} = props;
  const {t} = useTranslation();

  return (
    <div className="container">
      <div className="text-center mb-1">
        <img src={`${process.env.PUBLIC_URL}/logo.png`} alt={t('company.name')} />
      </div>
      <h1>{t('component.termAndCondition.title')}</h1>
      {Parser(t('component.termAndCondition.content'))}
      {children}
    </div>
  );
};

export default Privacy;
