/**
 * This file is part of Bimbo Vending QR.
 *
 * This source file is subject to a proprietary license that is bundled
 * with this source code in the file LICENSE.
 *
 * @author The Nubity Development Team <dev@nubity.com>
 */

import {IssueInterface as IssueModel} from '../model/IssueInterface';
import {usePostIssueMutation} from '../store/service/issueService';
import {IssueJsonIdIssueReadInterface} from '../store/service/model/IssueJsonIdIssueReadInterface';
import {CreateIssueInterface} from './model/CreateIssueInterface';

/**
 * Create a issue.
 */
const useCreateIssue = (code: string): CreateIssueInterface => {
  const [postIssueMutation, {isLoading}] = usePostIssueMutation();

  const createIssue = async (issue: IssueModel): Promise<IssueJsonIdIssueReadInterface> => {
    const {category, subCategory, comment, name, lastName, email, phone, spring, wallet} = issue;

    return postIssueMutation({
      category,
      subCategory,
      consumer: {email, name, lastName, phoneNumber: phone},
      description: comment,
      machine: {
        fma: code,
        springNumber: spring,
        wallet,
        fMA: code,
      },
    }).unwrap();
  };

  return {
    createIssue,
    isLoading,
  };
};

export default useCreateIssue;
