/**
 * This file is part of Bimbo Vending QR.
 *
 * This source file is subject to a proprietary license that is bundled
 * with this source code in the file LICENSE.
 *
 * @author The Nubity Development Team <dev@nubity.com>
 */

import apiService from './apiService';
import {APIClientParameterJsonIdApiClientParameterRead} from './model/APIClientParameterJsonIdApiClientParameterRead';
import {PaginationInterface} from './model/PaginationInterface';

const apiClientParameterService = apiService.injectEndpoints({
  /**
   * Handles requests from the API client parameter service.
   */
  endpoints: (builder) => ({
    getApiClientParameters: builder.query<APIClientParameterJsonIdApiClientParameterRead[], PaginationInterface>({
      query: (params: PaginationInterface) => ({
        url: '/api-client-parameters',
        method: 'GET',
        config: {params},
      }),
    }),
    getApiClientParameter: builder.query<APIClientParameterJsonIdApiClientParameterRead, string>({
      query: (id: string) => ({
        url: `/api-client-parameters/${id}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {useGetApiClientParametersQuery, useGetApiClientParameterQuery} = apiClientParameterService;

export default apiClientParameterService;
