/**
 * This file is part of Bimbo Vending QR.
 *
 * This source file is subject to a proprietary license that is bundled
 * with this source code in the file LICENSE.
 *
 * @author The Nubity Development Team <dev@nubity.com>
 */

import classNames from 'classnames';
import React from 'react';
import {useForm} from 'react-hook-form';
import type {RegisterOptions} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {Input, Button, Label} from 'reactstrap';
import CategoryListOption from '../component/CategoryListOption';
import InputFormGroup from '../component/InputFormGroup';
import Modal from '../component/Modal';
import Privacy from '../component/Privacy';
import useModal from '../hook/useModal';
import IssueTransformer from '../store/service/transform/IssueTransformer';
import categories from './categories';
import CategoryField from './CategoryField';
import IssueDTO from './dto/Issue';
import ErrorMessage from './ErrorMessage';
import {IssueFormInterface} from './model/IssueFormInterface';
import subCategories from './subCategories';

/**
 * Renders a form to create an issue.
 */
const IssueForm: React.FC<IssueFormInterface> = (props) => {
  const {fmaCode, onSubmit, isLoading} = props;
  const [categoryId, setCategoryId] = React.useState<string>();
  const [subcategoryId, setSubcategoryId] = React.useState<string>();
  const {t} = useTranslation();
  const {isModalActive, closeModal} = useModal();

  const categoriesWithSpringAvailable = React.useMemo((): string[] => {
    return [
      'subcategory.spring_did_not_rotate',
      'subcategory.not_delivery_the_product',
      'subcategory.delivered_expired_product',
      'subcategory.did_not_give_change',
      'subcategory.ate_billet_and_did_not_delivered_product',
      'subcategory.ate_coin_and_did_not_delivered_product',
      'subcategory.paid_with_card_and_did_not_delivered_product',
      'subcategory.product_stuck',
    ];
  }, []);

  const {handleSubmit, formState, register, reset, setValue} = useForm<IssueDTO>({
    defaultValues: {
      category: '',
      subCategory: '',
      // The value for `wallet` property is set to `false` to maintain compatibility with the API REST.
      wallet: 'false',
    },
  });

  const {errors} = formState;

  const requiredValidation: RegisterOptions = {
    required: {value: true, message: t('form.issue.validation.required')},
  };

  const isSpringFieldRequired = React.useMemo((): boolean => {
    const categorySelected = subCategories.find((subcategory): boolean => subcategory.id.toString() === subcategoryId);

    return (
      undefined !== subcategoryId &&
      undefined !== categorySelected &&
      categoriesWithSpringAvailable.includes(categorySelected.label)
    );
  }, [categoriesWithSpringAvailable, subcategoryId]);

  const {ref: firstnameRef, ...firstnameField} = register('firstname', {
    required: {value: true, message: t('form.issue.validation.firstname')},
  });
  const {ref: lastnameRef, ...lastnameField} = register('lastname', {
    required: {value: true, message: t('form.issue.validation.lastname')},
  });
  const {ref: phoneRef, ...phoneField} = register('phone', {
    required: {value: true, message: t('form.issue.validation.phone')},
  });
  const {ref: emailRef, ...emailField} = register('email', {
    required: {value: true, message: t('form.issue.validation.email')},
    pattern: {value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/, message: t('form.issue.validation.emailPattern')},
  });
  const {ref: springRef, ...springField} = register('spring', {
    required: isSpringFieldRequired ? requiredValidation.required : false,
  });

  register('category', requiredValidation);
  const {ref: subCategoryRef, ...subCategoryField} = register('subCategory', requiredValidation);
  const {ref: commentRef, ...commentField} = register('comment', requiredValidation);

  const selectCategory = (category: string) => {
    setCategoryId(category);
    setSubcategoryId('');
    setValue('category', category, {shouldValidate: true});
    setValue('subCategory', '');
    setValue('spring', '');
  };

  const createTicket = async (issue: IssueDTO) => {
    await onSubmit(IssueTransformer.transform(issue));

    reset({
      category: '',
      subCategory: '',
      wallet: '',
    });
  };

  return (
    <form className={classNames('row g-4 mt-3', {'d-none': isLoading})} onSubmit={handleSubmit(createTicket)}>
      <div className="col-12">
        <p className="fw-bold">{t('form.issue.title')}</p>
        <p className="fs-6 fw-bold">{t('form.issue.fma', {fma_code: fmaCode})}</p>
      </div>

      <div className="col-12 mt-2">
        <div className="row align-items-center">
          <div className="col-12">
            <p className="form-label mb-2">{t('form.issue.who_are_you')}</p>
          </div>
          <div className="col-2 text-center">
            <img src={`${process.env.PUBLIC_URL}/user_avatar.png`} alt="User info" className="w-100 icon-images" />
          </div>
          <div className="col-10">
            <div className="row">
              <div className="col-12 mt-2 mb-2">
                <Input
                  className="form-control shadow"
                  type="text"
                  id="first-name"
                  placeholder={t('form.issue.placeholder.firstname')}
                  innerRef={firstnameRef}
                  {...firstnameField}
                />
              </div>
              <div className="col-12 mb-2">
                <Input
                  className="form-control shadow"
                  type="text"
                  id="last-name"
                  placeholder={t('form.issue.placeholder.lastname')}
                  innerRef={lastnameRef}
                  {...lastnameField}
                />
              </div>
            </div>
          </div>
          <div className="col-12">
            <ErrorMessage message={errors?.firstname?.message || errors?.lastname?.message || undefined} />
          </div>
        </div>
      </div>

      <div className="col-12">
        <div className="row">
          <div className="col-12">
            <p className="form-label mb-2">{t('form.issue.contact')}</p>
          </div>
          <div className="col-2 text-center">
            <img src={`${process.env.PUBLIC_URL}/mail.png`} alt="Mail" className="w-100 icon-images" />
          </div>
          <div className="col-10 my-2">
            <Input
              className="form-control shadow"
              type="text"
              id="email"
              placeholder={t('form.issue.placeholder.email')}
              innerRef={emailRef}
              {...emailField}
            />
          </div>
          <div className="col-2 text-center">
            <img src={`${process.env.PUBLIC_URL}/phone.png`} alt="Phone" className="w-100 icon-images" />
          </div>
          <div className="col-10 mb-2">
            <Input
              className="form-control shadow"
              type="tel"
              id="phone"
              placeholder={t('form.issue.placeholder.phone')}
              innerRef={phoneRef}
              {...phoneField}
            />
          </div>
          <div className="col-12">
            <ErrorMessage message={errors?.email?.message || errors?.phone?.message || undefined} />
          </div>
        </div>
      </div>

      <div className="col-12">
        <div className="row">
          <div className="col-12">
            <p className="form-label">
              {t('form.issue.label.category')} <span className="link-danger">*</span>
            </p>
          </div>
        </div>
        <div className="row">
          {categories.map((category): JSX.Element => {
            return (
              <div className="col-4" key={`product-${category.id}`}>
                <CategoryField
                  id={category.id.toString()}
                  activeCategoryId={categoryId}
                  label={category.label}
                  type={category.type}
                  onCategoryChange={selectCategory}
                />
              </div>
            );
          })}
        </div>
        <div className="row">
          <div className="col-12">
            <ErrorMessage message={errors?.category?.message || undefined} />
          </div>
        </div>
      </div>

      <div className="col-12 mb-0">
        <Label htmlFor="subIssue" className="form-label">
          {t('form.issue.label.subcategory')} <span className="link-danger">*</span>
        </Label>
        <Input
          innerRef={subCategoryRef}
          id="subIssue"
          className="form-select shadow"
          disabled={undefined === categoryId}
          name={subCategoryField.name}
          onChange={(selectEvent) => {
            setSubcategoryId(selectEvent.target.value);
            subCategoryField.onChange(selectEvent);
          }}
          type="select"
        >
          <option disabled value="">
            {t('form.issue.placeholder.subcategory')}
          </option>

          <CategoryListOption
            categories={subCategories
              .filter((subCategory) => categoryId === subCategory.category.toString())
              .map((category) => ({...category, id: category.id.toString(), value: category.id.toString()}))}
          />
        </Input>
        {undefined !== errors.subCategory && <span className="text-danger mb-0">{errors.subCategory.message}</span>}
      </div>

      {isSpringFieldRequired && (
        <InputFormGroup
          formGroupClassName="col-12 mb-0"
          innerRef={springRef}
          required
          id="spring"
          label={t('form.issue.label.spring')}
          placeholder={t('form.issue.placeholder.spring')}
          type="text"
          errorMessage={errors.spring && errors.spring.message}
          {...springField}
        />
      )}

      <div className="col-12 mb-4">
        <Label htmlFor="comments" className="form-label">
          {t('form.issue.label.comment')} <span className="link-danger">*</span>
        </Label>
        <textarea
          className="form-control shadow"
          id="comments"
          rows={3}
          ref={commentRef}
          placeholder={t('form.issue.placeholder.comment')}
          {...commentField}
        />
        {undefined !== errors.comment && <span className="text-danger mb-0">{errors.comment.message}</span>}
      </div>

      <div className="col-12">
        <button onClick={closeModal} type="button" className="btn form-label text-center w-100 btn-link mb-0">
          {t('component.privacy')}
        </button>
        <Modal closeModal={closeModal} isOpen={isModalActive} title={t('component.privacy')}>
          <Privacy>
            <Button onClick={closeModal} className="btn btn-primary form-control btn-send-form" type="submit">
              {t('component.termAndCondition.agree')}
            </Button>
          </Privacy>
        </Modal>
      </div>

      <div className="col-12 mt-3 mb-5">
        <Button className="btn btn-primary form-control btn-send-form" type="submit">
          {t('form.issue.submit_button')}
        </Button>
      </div>
    </form>
  );
};

export default IssueForm;
