/**
 * This file is part of Bimbo Vending QR.
 *
 * This source file is subject to a proprietary license that is bundled
 * with this source code in the file LICENSE.
 *
 * @author The Nubity Development Team <dev@nubity.com>
 */

import React from 'react';
import {useTranslation} from 'react-i18next';
import useGetHelpdeskId from '../hook/useGetHelpdeskId';
import HelpdeskId from './HelpdeskId';
import HelpdeskIdStatusMessage from './HelpdeskIdStatusMessage';
import {SuccessMessageInterface} from './model/SuccessMessageInterface';

/**
 * Renders a success message with the ticket identifier.
 */
const SuccessMessage: React.FC<SuccessMessageInterface> = (props) => {
  const {identifier, timeToCancelRequest, waitingTimeToApplyRequest} = props;
  const {t} = useTranslation();

  const {helpdeskId, isTimeoutFinished} = useGetHelpdeskId(identifier, timeToCancelRequest, waitingTimeToApplyRequest);

  return (
    <div>
      <div className="row justify-content-center mb-5 mt-5">
        <img src={`${process.env.PUBLIC_URL}/success.png`} alt="success" className="img-fluid img-success" />
      </div>
      <div className="row justify-content-center mb-3">
        <div className="col-12">
          <p className="fs-1 fw-bold text-center text-success">{t('component.success_message.title')}</p>
          <HelpdeskId helpdeskId={helpdeskId} />
        </div>
      </div>
      <div className="row justify-content-center mb-5">
        <div className="col-12">
          <HelpdeskIdStatusMessage helpdeskId={helpdeskId} isTimeoutFinished={isTimeoutFinished} />
        </div>
      </div>
    </div>
  );
};

export default SuccessMessage;
