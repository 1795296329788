/**
 * This file is part of Bimbo Vending QR.
 *
 * This source file is subject to a proprietary license that is bundled
 * with this source code in the file LICENSE.
 *
 * @author The Nubity Development Team <dev@nubity.com>
 */

import React from 'react';
import {ErrorMessageInterface} from './model/ErrorMessageInterface';

/**
 * Renders an error message.
 */
const ErrorMessage: React.FC<ErrorMessageInterface> = (props) => {
  const {message} = props;

  if (undefined === message) {
    return null;
  }

  return <span className="text-danger mb-0">{message}</span>;
};

export default ErrorMessage;
