/**
 * This file is part of Bimbo Vending QR.
 *
 * This source file is subject to a proprietary license that is bundled
 * with this source code in the file LICENSE.
 *
 * @author The Nubity Development Team <dev@nubity.com>
 */

import React from 'react';

const useModal = (): {isModalActive: boolean; closeModal: VoidFunction} => {
  const [isModalActive, setModalActive] = React.useState(false);

  const closeModal = () => {
    setModalActive((status) => !status);
  };

  return {
    isModalActive,
    closeModal,
  };
};

export default useModal;
