/**
 * This file is part of Bimbo Vending QR.
 *
 * This source file is subject to a proprietary license that is bundled
 * with this source code in the file LICENSE.
 *
 * @author The Nubity Development Team <dev@nubity.com>
 */

import {createApi} from '@reduxjs/toolkit/query/react';
import requestBase from './requestBase';

const apiService = createApi({
  reducerPath: 'apiService',
  baseQuery: requestBase(''),
  endpoints: () => ({}),
  tagTypes: [],
});

export default apiService;
