/**
 * This file is part of Bimbo Vending QR.
 *
 * This source file is subject to a proprietary license that is bundled
 * with this source code in the file LICENSE.
 *
 * @author The Nubity Development Team <dev@nubity.com>
 */

import React from 'react';
import {useTranslation} from 'react-i18next';

export interface HelpdeskIdInterface {
  helpdeskId: string | undefined;
}

/**
 * Renders the helpdesk id.
 */
const HelpdeskId: React.FC<HelpdeskIdInterface> = (props) => {
  const {helpdeskId} = props;
  const {t} = useTranslation();

  if (undefined === helpdeskId) {
    return <div className="spinner-mini" />;
  }

  return (
    <p className="fs-6 fw-bold text-center">
      {t('component.success_message.helpdesk_id')} <span className="text-success">{helpdeskId}</span>
    </p>
  );
};

export default HelpdeskId;
