/**
 * This file is part of Bimbo Vending QR.
 *
 * This source file is subject to a proprietary license that is bundled
 * with this source code in the file LICENSE.
 *
 * @author The Nubity Development Team <dev@nubity.com>
 */

/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import React from 'react';
import {Label, Input, FormGroup} from 'reactstrap';
import {InputFormGroupInterface} from './model/InputFormGroupInterface';

/**
 * Renders an input field for the form.
 *
 * @todo Separate responsibilities between view and logic.
 */
const InputFormGroup: React.FC<InputFormGroupInterface> = (props) => {
  const {className, formGroupClassName, labelClassName, label, id, required, errorMessage, ...inputProperties} = props;

  return (
    <FormGroup className={classNames(formGroupClassName)}>
      <Label className={classNames('form-label', labelClassName)} htmlFor={id}>
        {label} {required && <span className="link-danger">*</span>}
      </Label>
      <Input className={classNames('form-control shadow', className)} id={id} {...inputProperties} />
      {undefined !== errorMessage && <span className="text-danger mb-0">{errorMessage}</span>}
    </FormGroup>
  );
};

export default InputFormGroup;
