/**
 * This file is part of Bimbo Vending QR.
 *
 * This source file is subject to a proprietary license that is bundled
 * with this source code in the file LICENSE.
 *
 * @author The Nubity Development Team <dev@nubity.com>
 */

/* eslint-disable filenames/no-index */
// "filenames/no-index" rule is disabled because this file is the entry point for "webpack".

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './js/locale/i18n';

const rootElement = document.getElementById('root');

if (null === rootElement) {
  throw new Error('Element with "root" id was not found.');
}

const root = ReactDOM.createRoot(rootElement);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
