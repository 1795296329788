/**
 * This file is part of Bimbo Vending QR.
 *
 * This source file is subject to a proprietary license that is bundled
 * with this source code in the file LICENSE.
 *
 * @author The Nubity Development Team <dev@nubity.com>
 */

import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import ReduxConnection from './js/component/ReduxConnection';
import Router from './js/router/Router';
import './style/style.scss';

/**
 * Render the initial component.
 */
function App() {
  return (
    <BrowserRouter>
      <ReduxConnection>
        <Router />
      </ReduxConnection>
    </BrowserRouter>
  );
}

export default App;
