/**
 * This file is part of Bimbo Vending QR.
 *
 * This source file is subject to a proprietary license that is bundled
 * with this source code in the file LICENSE.
 *
 * @author The Nubity Development Team <dev@nubity.com>
 */

import React from 'react';
import {useGetApiClientParametersQuery} from '../store/service/apiClientParameterService';
import {APIClientParameterJsonIdApiClientParameterRead} from '../store/service/model/APIClientParameterJsonIdApiClientParameterRead';
import {GetApiClientParametersInterface} from './model/GetApiClientParametersInterface';

/**
 * Returns the configurations for the requests.
 */
const useGetApiClientParameters = (): GetApiClientParametersInterface => {
  // Maximum time to wait for the response from the server defined in seconds.
  const defaultTimeToCancelRequest = 60;
  // Wait time between requests to the server defined in seconds.
  const defaultWaitingTimeToApplyRequest = 5;

  const [timeToCancelRequest, setTimeToCancelRequest] = React.useState(defaultTimeToCancelRequest);
  const [waitingTimeToApplyRequest, setWaitingTimeToApplyRequest] = React.useState(defaultWaitingTimeToApplyRequest);

  const {data: clientParameters} = useGetApiClientParametersQuery({});

  React.useEffect((): void => {
    if (undefined !== clientParameters) {
      clientParameters.forEach(({name, value}: APIClientParameterJsonIdApiClientParameterRead) => {
        if ('timeout' === name) {
          setTimeToCancelRequest(parseInt(value, 10));
        }

        if ('interval' === name) {
          setWaitingTimeToApplyRequest(parseInt(value, 10));
        }
      });
    }
  }, [clientParameters]);

  return {
    timeToCancelRequest,
    waitingTimeToApplyRequest,
  };
};

export default useGetApiClientParameters;
