/**
 * This file is part of Bimbo Vending QR.
 *
 * This source file is subject to a proprietary license that is bundled
 * with this source code in the file LICENSE.
 *
 * @author The Nubity Development Team <dev@nubity.com>
 */
import React from 'react';
import {Modal as ReactstrapModal, ModalHeader, ModalBody} from 'reactstrap';
import {ModalInterface} from './model/ModalInterface';

const Modal: React.FC<ModalInterface> = (props) => {
  const {closeModal, title, isOpen = false, children} = props;

  return (
    <ReactstrapModal isOpen={isOpen} toggle={closeModal} size="lg" scrollable>
      <ModalHeader toggle={closeModal}>{title}</ModalHeader>
      <ModalBody>{children}</ModalBody>
    </ReactstrapModal>
  );
};

export default Modal;
