/**
 * This file is part of Bimbo Vending QR.
 *
 * This source file is subject to a proprietary license that is bundled
 * with this source code in the file LICENSE.
 *
 * @author The Nubity Development Team <dev@nubity.com>
 */

import React, {PropsWithChildren} from 'react';
import {Provider} from 'react-redux';
import store from '../store/store';

/**
 * Returns the children property wrapped in the Redux context.
 */
const ReduxConnection: React.FC<PropsWithChildren> = (props) => {
  const {children} = props;

  return <Provider store={store}>{children}</Provider>;
};

export default ReduxConnection;
