/**
 * This file is part of Bimbo Vending QR.
 *
 * This source file is subject to a proprietary license that is bundled
 * with this source code in the file LICENSE.
 *
 * @author The Nubity Development Team <dev@nubity.com>
 */

import React from 'react';
import {useTranslation} from 'react-i18next';
import {CategoryListOptionInterface} from './model/CategoryListOptionInterface';

/**
 * Renders a list of `HTMLOptionElement`.
 */
const CategoryListOption: React.FC<CategoryListOptionInterface> = (props) => {
  const {categories} = props;
  const {t} = useTranslation();

  return (
    <>
      {categories.map(({id, label, value}) => {
        return (
          <option key={id} value={value}>
            {t(label as string)}
          </option>
        );
      })}
    </>
  );
};

export default CategoryListOption;
