/**
 * This file is part of Bimbo Vending QR.
 *
 * This source file is subject to a proprietary license that is bundled
 * with this source code in the file LICENSE.
 *
 * @author The Nubity Development Team <dev@nubity.com>
 */

import React from 'react';
import {Navigate, Routes, Route} from 'react-router-dom';
import Issue from '../page/Issue';
import PageLayout from '../page/PageLayout';

const Router = () => {
  return (
    <PageLayout>
      <Routes>
        <Route path="/" element={<Issue />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </PageLayout>
  );
};

export default Router;
