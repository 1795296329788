/**
 * This file is part of Bimbo Vending QR.
 *
 * This source file is subject to a proprietary license that is bundled
 * with this source code in the file LICENSE.
 *
 * @author The Nubity Development Team <dev@nubity.com>
 */

import React from 'react';
import {useSearchParams} from 'react-router-dom';
import ErrorMessage from '../component/ErrorMessage';
import Loader from '../component/Loader';
import SuccessMessage from '../component/SuccessMessage';
import IssueForm from '../form/IssueForm';
import useCreateIssue from '../hook/useCreateIssue';
import useGetApiClientParameters from '../hook/useGetApiClientParameters';
import {IssueInterface} from '../model/IssueInterface';

/**
 * Renders the issue page.
 */
const Issue: React.FC = () => {
  const [searchParams] = useSearchParams();
  const fmaCode = searchParams.get('fma');
  const {createIssue, isLoading} = useCreateIssue(fmaCode || '');
  const [ticketId, setTicketId] = React.useState<number>();
  const {timeToCancelRequest, waitingTimeToApplyRequest} = useGetApiClientParameters();

  const onSubmit = async (issue: IssueInterface): Promise<void> => {
    const ticket = await createIssue(issue);

    setTicketId(ticket.id);
  };

  if (null === fmaCode) {
    return (
      <ErrorMessage title="component.error_message.title" messages={['component.error_message.fma_code_is_required']} />
    );
  }

  if (undefined !== ticketId) {
    return (
      <SuccessMessage
        identifier={ticketId}
        timeToCancelRequest={timeToCancelRequest}
        waitingTimeToApplyRequest={waitingTimeToApplyRequest}
      />
    );
  }

  return (
    <>
      {isLoading && <Loader />}

      <IssueForm fmaCode={fmaCode} onSubmit={onSubmit} isLoading={isLoading} />
    </>
  );
};

export default Issue;
