/**
 * This file is part of Bimbo Vending QR.
 *
 * This source file is subject to a proprietary license that is bundled
 * with this source code in the file LICENSE.
 *
 * @author The Nubity Development Team <dev@nubity.com>
 */

const categories = [
  {id: 1, type: 'mechanic', label: 'form.issue.category.mechanical', value: 'category.mechanical'},
  {id: 2, type: 'product', label: 'form.issue.category.product', value: 'category.product'},
  {id: 3, type: 'money', label: 'form.issue.category.money', value: 'category.money'},
];

export default categories;
